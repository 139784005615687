.booking_widget__app.step2 {
  box-shadow: none;
  margin-top: 40px;
  width: 100%;
  max-width: 500px;
  margin: 40px auto;
  padding: 0 30px;
  background: inherit;
  label {
    margin-bottom: 0px;
  }
}

.step2.booking_widget__app .check-container .check-icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.step2.booking_widget__app .check-container .check-step {
  text-align: center;
}
.step2.booking_widget__app .check-container .check-text {
  font-size: 12px;
}

.step2 h1 {
  color: #222a2f;
  font-size: 24px;
  letter-spacing: 0px;
  margin-bottom: 18px;
}

.step2 h2 {
  font-family: 'acumin-pro', sans-serif;
  color: #222a2f;
  font-size: 20px;
  letter-spacing: 0px;
  margin-bottom: 0px;
  line-height: 1.3em;
}

.step2 .icons {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.step2 .icons span {
  display: inline;
  vertical-align: middle;
}

.step2 .icons img {
}

.step2 .info-container {
  border: 1px solid #222a2f;
  width: 100%;
  padding: 30px;
  margin: 20px auto;
  /* padding: 25px 5px 5px 22px; */
  /* width: 195px; */
  /* height: 195px; */

  /* font-weight: 500; */
}
.ticket-form .info-container {
  width: 100%;
  height: auto;
}

.step2 .info-container.experience {
  padding: 12px 5px 5px 22px;
}

.step2 h3 {
  color: #222a2f;
  font-size: 16px;
  margin: 0;
  font-family: 'acumin-pro', sans-serif;
}

.step2 p {
  color: #707070;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1.2em;
}

.step2 .counter {
  color: #222a2f;
  margin-bottom: 40px;
  font-size: 14px;
}

.step2 .afterHrh2 {
  margin-bottom: 8px;
}

.step2 .submit {
  padding: 10px 20px;
}

.step2 input {
  padding: 5px 15px;
  height: 40px;
  font-size: 16px;
  border-radius: 10px;
  color: #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.step2 .footer {
  color: #707070;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 1.2em;
  font-size: 12px;
  margin-bottom: 5px;
}

.booking_widget__powered_by {
  color: #707070;
  font-size: 12px !important;
  text-align: center;
  margin-top: 10px;
}

/* @media only screen and (max-width: 769px) {
  .container {
    max-width: 100%;
  }
} */

/* @media only screen and (max-width: 769px) {
  .step2 .info-container {
    color: white;
    background: radial-gradient(circle farthest-corner at 114% 130%, #fd6c57 26%, #fd3366);
    border-radius: 20px;
    padding: 25px 5px 5px 22px;
    width: 100%;
    height: 155px;
    margin-top: -7px;
    margin-bottom: 14px;
    font-weight: 500;
  }
  .ticket-form .info-container {
    width: 100%;
    height: auto;
    padding: 16px;
  }

  .step2 .icons {
    margin-bottom: 0;
  }

  .step2 .icons.small-font {
    font-size: 14px;
  }
} */
