.step3.booking_widget__app {
  max-width: 400px;
  box-shadow: none;
  margin: 40px auto;
  padding: 0 30px;
  background: inherit;
}

.step3.booking_widget__app .icons {
  display: block;
  margin-bottom: 20px;
  color: #222a2f;
}

.step3.booking_widget__app .icons:last-child {
  /* margin-bottom: 0; */
}

.step3.booking_widget__app .icons img,
.step3.booking_widget__app .icons span {
  display: inline-block;
  vertical-align: middle;
}
.step3.booking_widget__app .info-wrap {
  padding: 30px;
  border: 1px solid #222a2f;
  margin-top: 20px;
}

.step3.booking_widget__app .info-btn {
  background: #222a2f;
  color: #fff;
  padding: 10px 20px;
  border-radius: 0;
  width: 100%;
}

.step3.booking_widget__app .icons svg {
  margin-right: 8px;
}

.step3.booking_widget__app .check-container {
}

.step3.booking_widget__app .check-container .check-icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.step3.booking_widget__app .check-container .check-step {
  text-align: center;
}
.step3.booking_widget__app .check-container .check-text {
  font-size: 12px;
}

.step3.booking_widget__app h2 {
  font-size: 40px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
}

.step3.booking_widget__app .title h2 {
  font-size: 18px;
  letter-spacing: 0px;
  color: #222a2f;
  margin-bottom: 20px;
}

.step3.booking_widget__app .booking_widget__powered_by {
  color: #707070;
  font-size: 12px;
  text-align: center;
  margin-bottom: 19px;
  margin-top: 40px;
}

@media only screen and (max-width: 769px) {
  .step3.booking_widget__app {
    max-width: 100%;
  }
}
