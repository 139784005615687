.wb-first-box {
    border-bottom: solid 1px #E7E7E7;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wb-second-box {
    padding-left: 20px;
    padding-top: 20px;
}

.wb-third-box {
    padding: 20px;
    border-bottom: solid 1px #E7E7E7;
}