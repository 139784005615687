@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.wabi-root {
    background-color: #000;
    min-height: 100vh;
}

.wabi-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 80px;
    color: #fff;
}

.wabi-header {
    font-family: Inter !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 29.05px !important;
    text-align: left;
}

.wabi-title {
    font-family: Inter !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 19.36px !important;
    text-align: left !important;
}

.wabi-subtitle {
    font-family: Inter;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: #8D8D92 !important;
}

.clock {
    width: 26px !important;
    height: 26px !important;
    color: #3478F6 !important;
}

